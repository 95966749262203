import React from 'react';

import { graphql } from 'gatsby';

import { Box, Typography } from '@mui/material';

function Credits(props) {
    const { nodes } = props.data.allMarkdownRemark;

    const imgAttributions = nodes.map(
        ({ frontmatter: { image_attribution } }) => image_attribution
    );
    const imgAttributionsUnique = imgAttributions.filter(
        (a, index) => imgAttributions.indexOf(a) === index
    );

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h5">Image Credits</Typography>
            <Typography variant="subtitle1">
                Credits for images used in the blog posts and home page
            </Typography>
            {/* HomePage */}
            <Typography variant="caption">
                {/* <a href="http://www.freepik.com">
                        Designed by vectorjuice / Freepik
                    </a> */}
                <a href="https://www.freepik.com/photos/background">
                    Background photo created by tirachardz - www.freepik.com
                </a>
                <br></br>
                <a href="https://storyset.com/people">
                    People illustrations by Storyset
                </a>
                {/* <a href="https://www.freepik.com/vectors/technology">
                    Technology vector created by stories - www.freepik.com
                </a>
                <br></br>
                <a href="https://www.freepik.com/vectors/business">
                    Business vector created by stories - www.freepik.com
                </a>
                <br></br>
                <a href="https://www.freepik.com/vectors/website">
                    Website vector created by stories - www.freepik.com
                </a> */}
            </Typography>
            {/* Blogs */}
            {imgAttributionsUnique.map((image_attribution, index) => (
                <div key={index}>
                    <Typography
                        variant="caption"
                        dangerouslySetInnerHTML={{
                            __html: image_attribution,
                        }}
                    />
                </div>
            ))}
            <br />
            <a href="https://commons.wikimedia.org/wiki/File:Technology-Adoption-Lifecycle.png">
                Craig Chelius
            </a>
            <br />
        </Box>
    );
}

export default Credits;

export const pageQuery = graphql`
    query CreditsQuery {
        allMarkdownRemark {
            nodes {
                frontmatter {
                    image_attribution
                }
            }
        }
    }
`;
